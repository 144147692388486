/* src/FAQ.css */
.faq-container {
  width: 80%;
  margin: 0 auto;
  padding: 20px;
}

.faq-list {
  list-style: none;
  padding: 0;
}

li {
  margin: 10px 0;
}

details {
  cursor: pointer;
}

summary {
  font-weight: 600;
  font-size: 18px;
}

p {
  margin-top: 5px;
  margin-left: 10px;
  display: none;
}

details[open] p {
  display: block;
}
